import { ComponentProps, Fragment, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { StepProps } from './DistributionWizard';
import StandardModal from '../shared/modal/variants/StandardModal';
import InviteOrSearchContent from '../user/InviteOrSearchContent';
import usePermissions from '../../hooks/permissions/usePermissions';
import { Option } from '../Option';
import { Roles } from '../../models/Role';
import { useTranslation } from 'react-i18next';
import UserInvitation from '../../models/UserInvitation';
import { systemDefaultLanguageCode } from '../../types/Languages';
import ClientService from '../../services/ClientService';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { PeopleType } from '../../models/Distribution';
import { useCurrentClient } from '../../global-state/Clients';

const EMPTY_USER: UserInvitation = {
  email: '',
  role: Roles.Employee,
  firstName: '',
  lastName: '',
  clientId: '',
  clientName: '',
  languageCode: systemDefaultLanguageCode,
};

type Props = {
  onValid?: (valid: boolean) => void;
  onToInviteChanged?: (toInvite: UserInvitation) => void;
};

type Handle = {
  triggerInvite: () => Promise<void>;
};

const DistributionInviteNewStep = forwardRef<Handle, StepProps & Props>(function DistributionInviteNewStep(props, ref) {
  const { setCurrentStep, setMembers, onValid, onToInviteChanged } = props;

  const { t } = useTranslation(['distribution', 'organisation']);
  const currentClient = useCurrentClient((x) => x.value);
  const hasPermission = usePermissions();
  const [valid, setValid] = useState(false);
  const [toInvite, setToInvite] = useState<UserInvitation>(EMPTY_USER);
  const toasts = useToasts();

  useEffect(() => {
    onValid && onValid(valid);
  }, [onValid, valid]);

  useEffect(() => {
    onToInviteChanged && onToInviteChanged(toInvite);
  }, [onToInviteChanged, toInvite]);

  const roleFilter = useCallback(
    (role: Option<string, string>) => {
      return hasPermission(role.id) && role.id !== Roles.SuperAdmin;
    },
    [hasPermission],
  );

  const inviteUser = useCallback(() => {
    return ClientService.inviteUser(currentClient!.id, toInvite)
      .then((res) => {
        if (res.data.isNewUser) {
          toasts.addToast({
            title: t('organisation:permissions.toasts.invited'),
            description: t('organisation:permissions.toasts.invited-desc', {
              email: toInvite?.email,
              client: currentClient!.name,
            }),
            type: ToastType.SUCCESS,
            expiresInMs: 5000,
          });
        } else {
          toasts.addToast({
            title: t('organisation:permissions.toasts.invite-failed'),
            description: t('organisation:permissions.toasts.invite-failed-desc'),
            type: ToastType.INFO,
            expiresInMs: 10000,
          });
        }
        setMembers((prev) => [
          ...prev,
          { id: res.data.userId, firstName: toInvite.firstName ?? '', lastName: toInvite.lastName ?? '', type: PeopleType.Member },
        ]);
        setCurrentStep && setCurrentStep('members');
      })
      .catch((err) => {
        toasts.addToast({
          title: t('organisation:permissions.toasts.invite-failed'),
          description: err?.data?.meta?.message,
          type: ToastType.ERROR,
        });
      });
  }, [currentClient, setCurrentStep, setMembers, t, toInvite, toasts]);

  useImperativeHandle(ref, () => ({
    triggerInvite() {
      return inviteUser();
    },
  }));

  const Wrapper = setCurrentStep ? StandardModal : Fragment;
  const wrapperProps = setCurrentStep
    ? ({
        title: t('steps.invite-new.title'),
        onConfirmClick: inviteUser,
        confirmDisabled: !valid,
        onCancelClick: () => setCurrentStep && setCurrentStep('members'),
        confirmButtonTitle: t('steps.invite-new.confirm'),
        cancelButtonTitle: t('steps.invite-new.cancel'),
      } as ComponentProps<typeof StandardModal>)
    : {};

  return (
    <Wrapper {...wrapperProps}>
      <InviteOrSearchContent inviteMode clientId={currentClient!.id} onInputsValid={setValid} filterRoles={roleFilter} onUserChange={setToInvite} />
    </Wrapper>
  );
});

export default DistributionInviteNewStep;
